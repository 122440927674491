import $ from 'jquery'
import slick from 'slick-carousel'
import Foundation from 'foundation-sites'
import AOS from 'aos'
import axios from 'axios'
import Turbolinks from 'turbolinks'

window.jQuery = $
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

function AOSinit() {
  AOS.init({
    duration: 500
  })
}

Turbolinks.start()
require('@fancyapps/fancybox')

/** Google Analytics */
require('./gtag')
window.dataLayer = window.dataLayer || []
function gtag() {
  dataLayer.push(arguments)
}

$(document).foundation()
$(document).on('turbolinks:load', function() {
  turbolinksOnLoad()
})
$(document).on('turbolinks:before-cache', function() {
  $('.slider').slick('unslick')
  // AOSinit()
})

/**
 *
 * on document READY
 *
 */
$(document).ready(function() {
  AOSinit()
  $.fancybox.defaults.hash = false
})

/**
 * Functions that need to reload on each turbolinks:load
 */
function turbolinksOnLoad() {
  $(document).foundation()
  AOSinit()
  iniSlider()
  floatLabels()
  stickTopbar()

  /** Google Analytics */
  gtag('js', new Date())
  gtag('config', 'UA-65076305-2', {
    page_title: event.target.title,
    page_path: event.data.url.replace(
      window.location.protocol + '//' + window.location.hostname,
      ''
    )
  })
  /** // */

  $('.openMenu').click(() => {
    openMenu()
  })
  $('.openMenuSearch').click(() => {
    openMenu(true, true)
  })
  $('.close').click(function() {
    $(this)
      .closest('.showing')
      .removeClass('showing')
  })

  $('form')
    .not('.noajax')
    .submit(function(e) {
      e.preventDefault()
      let form = $(this)
      let submitButton = form.find('button[type="submit"]')
      let submitButtonText = submitButton.text()
      let formData = form.serialize()
      form.addClass('loading')
      form
        .find('button[type="submit"]')
        .prop('disabled', true)
        .text('Enviando...')
      form.find('.form-control__error').text('')
      form.find('.form-control').removeClass('error')
      axios
        .post(form.attr('action'), formData)
        .then(res => {
          form.find('.form-control').slideUp('fast')
          form.find('button[type="submit"]').text('Enviado!')
        })
        .catch(error => {
          if (error.response.data)
            for (let [key, val] of Object.entries(error.response.data)) {
              let input = form.find(`[name='${key}']`)
              input
                .closest('.form-control')
                .addClass('error')
                .find('.form-control__error')
                .text(val)
            }
          submitButton.prop('disabled', false).text(submitButtonText)
        })
        .finally(function() {
          form.removeClass('loading')
        })
    })
}

/**
 * --------------------------------------------------
 * Window scroll functions
 * --------------------------------------------------
 */

$(window).scroll(function() {
  stickTopbar()
})

/**
 * --------------------------------------------------
 * Slick slider
 * --------------------------------------------------
 */
function iniSlider() {
  if ($('.slider')) {
    $('.slider').slick({
      arrows: false,
      rows: 0, // prevents adding a wrapper div
      autoplay: true
    })
  }
}

/**
 * --------------------------------------------------
 * Stick topbar
 * --------------------------------------------------
 */
function stickTopbar() {
  let pos = $(window).scrollTop(),
    topbar = $('.topbar')
  if (pos > topbar.outerHeight()) {
    topbar.addClass('sticky')
  } else {
    topbar.removeClass('sticky')
  }
}

/**
 * --------------------------------------------------
 * Menu
 * --------------------------------------------------
 */

function openMenu(opened = true, focusSearch = false) {
  if (opened) {
    $('.menu').addClass('showing')
  } else {
    $('.menu').removeClass('showing')
  }
  if (focusSearch)
    setTimeout(() => {
      $(".menu__search input[name='s']").focus()
    }, 200)
}

/**
 * --------------------------------------------------
 * FLOAT LABES
 * --------------------------------------------------
 * Function to transform labels like placeholders
 * and floats to top when focused or filled
 *
 */

function floatLabels() {
  $('.form-control').each(function() {
    var formGroup = $(this),
      field = formGroup
        .children('input, textarea')
        .first()
        .not(':hidden')

    // check empty fields
    formGroup.toggleClass('is-filled', !isEmptyField(field))

    // on form-control click focus on field
    formGroup.click(function() {
      if (!field.is(':focus')) {
        field.focus()
      }
    })

    // if field has autofocus attribute
    if (field.attr('autofocus')) {
      formGroup.addClass('is-active')
    }

    // focus & blur
    field.focusin(function() {
      formGroup.addClass('is-active')
    })
    field.blur(function() {
      formGroup.removeClass('is-active')
      if (isEmptyField(field) === true) {
        formGroup.removeClass('is-filled')
      } else {
        formGroup.addClass('is-filled')
      }
    })
  })
}

function isEmptyField(el) {
  return !el.val()
}

function checkErrorInputs() {
  var el, firstError, scrollTarget
  $('.form-control').each(function() {
    el = $(this)
    el.children('.form-error-list').each(function() {
      el.addClass('is-invalid-input')
    })
  })

  firstError = $('.is-invalid-input').first()

  if (firstError.length > 0) {
    setTimeout(function() {
      $('html, body').animate(
        {
          scrollTop: (firstError.offset().top -= $(
            'body>header>nav'
          ).outerHeight())
        },
        800
      )
    }, 300)
  }
}
